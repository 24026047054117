//
// HS Video Bg
//

// Imports
@import "./variables";

[data-hs-video-bg-options] {
	overflow: hidden;
}

.hs-video-bg-preview,
.hs-video-bg-video {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 100%;
	transform: translate(-50%, -50%);
}

.hs-video-bg-preview {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
}

.hs-video-bg-video iframe,
.hs-video-bg-video video {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
}

.hs-video-bg-video video {
  object-fit: cover;
}