// user-variables.scss
// Use this to overwrite Bootstrap and Front variables
//


// Example of a variable override to change Front's primary color and font size
// Remove the "//" to comment it in and see it in action!
$primary: #ffb800;
// $font-size-base: .875rem;
// $gray-100: #F7F7F7;


// The color of the SVG loader should be modified manually.
// See assets/svg/components/circle-preloader.svg




@media screen and (max-width: 767.98px) {
    //* { color: blue; }
    
    .video-bg {
        position: relative;
        min-height: 25vh !important;
    }
}

.button {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #677788;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    background-color: transparent;
    border: 0.0625rem solid transparent;
    padding: 0.6125rem 1rem;
    font-size: 1rem;
    border-radius: 0.3125rem;
    transition: all 0.2s ease-in-out;
}

.btn-barry {
    color: #fff;
    background-color: #16283c;
    border-color: #16283c;
}

    .btn-barry:hover {
        color: #fff;
        background-color: #ffb800;
        border-color: #fec841;
    }

    .btn-check:focus + .btn-barry, .btn-barry:focus {
        color: #fff;
        background-color: #e7a809;
        border-color: #fec841;
        box-shadow: 0 0 0 0 rgba(85, 145, 255, 0.5);
    }

    .btn-check:checked + .btn-barry, .btn-check:active + .btn-barry, .btn-barry:active, .btn-barry.active, .show > .btn-barry.dropdown-toggle {
        color: #fff;
        background-color: #fec841;
        border-color: #ffb800;
    }

        .btn-check:checked + .btn-barry:focus, .btn-check:active + .btn-barry:focus, .btn-barry:active:focus, .btn-barry.active:focus, .show > .btn-barry.dropdown-toggle:focus {
            box-shadow: 0 0 0 0 rgba(85, 145, 255, 0.5);
        }

    .btn-barry:disabled, .btn-barry.disabled {
        color: #fff;
        background-color: #16283c;
        border-color: #16283c;
    }

.btn-alternate {
    color: #ffb800;
    background-color:#fff;
    border-color:#fff;
}
    
    .btn-alternate:hover{
        color:#fff;
        background-color:#ffb800;
        border-color:#ffb800;

    }
    
.hc-stars {
    color:#ffb800;
}

.text-barry {
    color: #ffaa6b
}