/*------------------------------------
  Page Preloader
------------------------------------*/

.page-preloader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $page-preloader-z-index;
  background-color: $page-preloader-bg-color;
}

.page-preloader-middle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}